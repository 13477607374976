/**
 * site
 **/

// Initilize debugging with 'localStorage.debug = true' in your console
var log = bows('site');

// Initialize debounce

// log('some var we need to check');
var site = {
  init: function() {
    // site.reframe();
    // modalJs.open('.trigger-modal', 'Something cool here!');
  },
  reframe: function() {
    // reframe('iframe[allowfullscreen]');
  }
};
jQuery(document).ready(function() {
  site.init();
  if (jQuery('body').hasClass('home')) {
    home.init();
  }
  testimonials.init();
  contactUs.init();
  logoList.init();
  dropdownSlider.init();
});

var debounceActions = function() {
  if (jQuery('body').hasClass('home')) {
    home.setHero();
  }
  if (jQuery('body').width() >= 1200) {
    mobileMenu.mobileMenuHide();
  }
};
var debounceCallback = debounce(debounceActions, 225);

jQuery(window).resize(function() {
  debounceCallback();
});
