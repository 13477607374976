/**
 * home
 **/

var home = {
  init: function() {
    var log = bows('home');
    this.setHero(log);
  },
  setHero: function(log) {
    // Defer script until blocking scripts execute
    // window.setTimeout(function() {
    //   // var sectionHeaderHeight = document.getElementById('section-header')
    //     // .clientHeight;
    //   //this.log(sectionHeaderHeight);
    //   var homeHero = document.getElementsByClassName('home-hero');//check what is being measured here
    //   homeHero[0].style.marginTop = -sectionHeaderHeight + 'px';
    //   homeHero[0].style.paddingTop = sectionHeaderHeight + 'px';
    // }, 0);
  }
}
