/**
 * dropdownSlider
 **/

var dropdownSlider = {
    init: function () {
        this.dropdownShow();
    },

    dropdownShow: function (element, animation) {
      var acc = document.getElementsByClassName('dropdown-slider__content');
      var i;

      for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() { 
          var c = 0;
          while (c < acc.length) {
            acc[c++].className = 'dropdown-slider__content';
          }
          this.className = 'active dropdown-slider__content';
          this.addEventListener("click", function() {
            if(! this.classList.contains('active') ) {
            this.classList.add('active');
            } else {
              this.classList.remove('active');
            }
          });
      });
    }
  }
}

