/**
 * Contact Us panel
 **/

var contactUs = {
  init: function() {
    var log = bows('contact us');
    this.contactClick(log);
    this.contactStore(log);
    this.contactGet(log);
  },
  contactClick: function(log) {
    var locationListItems = document.getElementsByClassName(
      'contact-us__location'
    );
    Array.prototype.forEach.call(locationListItems, function(
      locationListItem,
      i
    ) {
      locationListItem.children[0].onclick = function(e) {
        //log('click' + i);
        Array.prototype.forEach.call(locationListItems, function(el) {
          el.classList.remove('contact-us__location--active');
        });
        this.parentElement.classList.add('contact-us__location--active');
        contactUs.contactShow(i);
        e.preventDefault();
      };
    });
    //og(locationListItems);
  },
  contactShow: function(locationIndex) {
    var locationsRightColumn = document.getElementsByClassName(
      'contact-us__right-column'
    );
    var locationsReceiver = document.getElementsByClassName(
      'contact-us__blurb-receiver'
    );
    var locationsTitle = locationsReceiver[0].querySelector(
      '.contact-us__location-title'
    );
    var locationsBlurb = locationsReceiver[0].querySelector(
      '.contact-us__location-blurb'
    );
    var locationsImage = locationsReceiver[0].querySelector(
      '.contact-us__location-image'
    );
    var locationsLink = locationsReceiver[0].querySelector(
      '.contact-us__location-link'
    );

    // Attach nodes to variables.
    var locationsTitleReplacements = document.querySelectorAll(
      '.contact-us__location-data-js .contact-us__location-title-js'
    );
    var locationsBlurbReplacements = document.querySelectorAll(
      '.contact-us__location-data-js .contact-us__location-blurb-js'
    );
    var locationsImageReplacements = document.querySelectorAll(
      '.contact-us__location-data-js .contact-us__location-image-js'
    );
    var locationsLinkReplacements = document.querySelectorAll(
      '.contact-us__location-data-js .contact-us__location-link-js'
    );

    // Replace HTML in nodes with content from placeholders.
    locationsTitle.innerHTML =
      locationsTitleReplacements[locationIndex].innerHTML;
    locationsBlurb.innerHTML =
      locationsBlurbReplacements[locationIndex].innerHTML;
    locationsImage.style.backgroundImage =
      'url(' + locationsImageReplacements[locationIndex].innerHTML + ')';
    locationsRightColumn[0].style.backgroundImage =
      'url(' + locationsImageReplacements[locationIndex].innerHTML + ')';
    locationsLink.innerHTML =
      locationsLinkReplacements[locationIndex].innerHTML;
  },
  contactStore: function(log) {
    //log('contact store init');
  },
  contactGet: function(log) {
    //log('contact get init');
  }
};
