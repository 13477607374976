/**
 * testimonials
 **/

var testimonials = {
  init: function() {
    var log = bows('testimonials');
    this.testimonialsSlides(log);
  },
  testimonialsSlides: function(log) {
    // Check that tiny slider is initialized
    if (typeof tns === 'function') {
      var tnsCarousel = document.querySelectorAll('.testimonials__slider');
      tnsCarousel.forEach(function(testimonialSet) {
        var slider = tns({
          autoplay: true,
          autoplayButtonOutput: false,
          autoplayTimeout: 10000,
          controls: false,
          container: testimonialSet,
          gutter: 15,
          items: 1,
          nav: false,
          speed: 500,
          preventScrollOnTouch: 'auto',
          responsive: {
            576: {
              gutter: 25,
              items: 1,
              slideBy: 1
            },
            992: {
              gutter: 13,
              items: 2,
              slideBy: 2
            },
            1200: {
              gutter: 13,
              items: 2,
              slideBy: 2
            }
          }
        });
      });
    }
  }
};
