/**
 * Logo list
 **/

var logoList = {
  init: function() {
    var log = bows('logo-list');
    this.logoListItems(log);
  },
  logoListItems: function(log) {
    // Check that tiny slider is initialized
    if (typeof tns === 'function') {
      var tnsCarousel = document.querySelectorAll('.logo-list__slider');
      tnsCarousel.forEach(function(logoListSet) {
        var slider = tns({
          autoplay: true,
          autoplayButtonOutput: false,
          autoplayTimeout: 3000,
          controls: false,
          container: logoListSet,
          gutter: 15,
          items: 2,
          slideBy: 2,
          nav: false,
          speed: 600,
          preventScrollOnTouch: 'auto',
          responsive: {
            576: {
              gutter: 25
            },
            992: {
              gutter: 13,
              items: 4,
              slideBy: 4
            }
          }
        });
      });
    }
  }
};
